import { GetPodiumResults, GetRaces } from "api/openApi.ts";
import { useEffect, useState } from "react";

const useRaces = () => {
  const [races, setRaces] = useState([]);
  const [podiumResults, setPodiumResults] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, SetError] = useState();

  // use effect runs on load
  useEffect(() => {
    getRaces();
    getPodiumPositions();
  }, []);

  const getRaces = async () => {
    setIsLoading(true);
    try {
      const races = await GetRaces();
      setRaces(races);
    } catch (e) {
      if (e instanceof Error) {
        SetError(e);
      }
    }
    setIsLoading(false);
  };

  const getPodiumPositions = async () => {
    setIsLoading(true);
    try {
      const podiumRaces = await GetPodiumResults();
      setPodiumResults(podiumRaces);
    } catch (e) {
      if (e instanceof Error) {
        SetError(e);
      }
    }
    setIsLoading(false);
  };

  return {
    podiumResults,
    races,
    isLoading,
    error,
  };
};

export default useRaces;
