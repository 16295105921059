import { IsExpired } from "./jwt";

const secureApi: string = process.env.REACT_APP_SECURE_API_URL;
const ApiKey: string = process.env.REACT_APP_SECURE_API_KEY;

const expiredAndRedirect = function () {
  // document.cookie = "idToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.location.href = "/login?redirectTo=" + document.location;
};

const AwardMembership = async function (
  token: string,
  membershipNumber: string,
) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(
    secureApi + "awardmembership/" + membershipNumber,
    {
      method: "GET",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + token,
      },
    },
  ).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 2XX response recieved";
    }
  });

  return response;
};

const GetPrivateStatistics = async function (token: string) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "privatestats", {
    method: "GET",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

const GetMember = async function (token: string, membershipNumber: string) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "members/" + membershipNumber, {
    method: "GET",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

const DeleteMember = async function (token: string, membershipNumber: string) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "members/" + membershipNumber, {
    method: "DELETE",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
  }).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 2XX response recieved";
    }
  });

  return response;
};

const DeleteSession = async function (token: string, sessionID: string) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "sessions/" + sessionID, {
    method: "DELETE",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
  }).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 2XX response recieved";
    }
  });

  return response;
};

const AddRaceResult = async function (token: string, raceResult: RaceResult) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "results", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(raceResult),
  }).then((data) => {
    if (data.status === 200) {
      return "";
    } else {
      throw data.json();
    }
  });

  return response;
};

const UpsertSession = async function (token: string, session: Session) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "sessions", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(session),
  }).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 2XX response recieved";
    }
  });

  return response;
};

const MakeCoach = async function (
  token: string,
  membershipNumber: string,
  revoke: boolean,
) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(
    secureApi + "makecoach/" + membershipNumber + "?revoke=" + revoke,
    {
      method: "GET",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + token,
      },
    },
  ).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 2XX response recieved";
    }
  });

  return response;
};

const MakeAdmin = async function (
  token: string,
  membershipNumber: string,
  revoke: boolean,
) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(
    secureApi + "makeadmin/" + membershipNumber + "?revoke=" + revoke,
    {
      method: "GET",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + token,
      },
    },
  ).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

const MakeJuniorAdult = async function (
  token: string,
  membershipNumber: string,
) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(
    secureApi + "juniortosenior/" + membershipNumber,
    {
      method: "GET",
      headers: {
        "x-api-key": ApiKey,
        Authorization: "Bearer " + token,
      },
    },
  ).then((data) => {
    if (data.status === 204) {
      return "";
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

const UpdateCoachDetails = async function (
  token: string,
  membershipNumber: string,
  body: UpdateCoachRequest,
) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "coachupdate/" + membershipNumber, {
    method: "PATCH",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

const UpdateMemberDetails = async function (
  token: string,
  membershipNumber: string,
  body: UpdateMemberRequest,
) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "members/" + membershipNumber, {
    method: "PATCH",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

const GetMailingList = async function (token: string, mailList: string) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "maillist?mailingList=" + mailList, {
    method: "GET",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => {
      if (res.ok && res.status === 200) {
        return res.blob();
      } else {
        throw "Non 200 response recieved";
      }
    })
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = mailList + "_MAILING_LIST.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    });

  return response;
};

const GetAllMembers = async function (token: string) {
  if (IsExpired(token)) {
    expiredAndRedirect();
  }

  const response = await fetch(secureApi + "members", {
    method: "GET",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + token,
    },
  }).then((data) => {
    if (data.ok && data.status === 200) {
      return data.json();
    } else {
      throw "Non 200 response recieved";
    }
  });

  return response;
};

enum CoachLevel {
  LEVEL_1 = "Level 1",
  LEVEL_2 = "Level 2",
  LEVEL_3 = "Level 3",
}

interface Session {
  id: string;
  day: string;
  title: string;
  location: string;
  description: string;
  price: string;
  time: string;
  headline: string;
}

interface UpdateCoachRequest {
  isJuniorCoach: boolean;
  isCoach: boolean;
  isActivator: boolean;
  coachLevel: CoachLevel;
  btfMembershipNumber: string;
  btfCoachCertificateNumber: string;
  firstAidCertificateNumber: string;
  firstAidCertificateExpiryDate: string;
  dbsCertificateNumber: string;
  dbsCertificateExpiryDate: string;
  safegaurdingCertificateNumber: string;
  safegaurdingCertificateExpiry: string;
}

interface UpdateMemberRequest {
  familyName: string;
  gender: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  postCode: string;
  lat: string;
  long: string;
  parentFirstName: string;
  parentFamilyName: string;
  parentEmailAddress: string;
  parentPhoneNumber: string;
}

interface RaceResult {
  raceId: string;
  positionInAgeGroup: string;
  totalCompetitorsInAgeGroup: string;
  championshipCategory: string;
}

export {
  expiredAndRedirect,
  DeleteSession,
  GetMember,
  GetAllMembers,
  UpdateCoachDetails,
  MakeCoach,
  MakeAdmin,
  DeleteMember,
  AwardMembership,
  GetMailingList,
  GetPrivateStatistics,
  MakeJuniorAdult,
  UpdateMemberDetails,
  UpsertSession,
  AddRaceResult,
};
