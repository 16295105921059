import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import useRaces from "../hooks/useRaces";
import { AddRaceResult } from "api/secureApi.ts";
import { GetCookie } from "api/jwt";

export default ({ isOpen, onClose, btnRef }) => {
  const [isAspiring, setIsAspiring] = useState(false);
  const { races } = useRaces();
  const toast = useToast();

  const [selectedRace, setSelectedRace] = useState();
  const [position, setPosition] = useState();
  const [totalCompetitors, setTotalCompetitors] = useState();
  const [validate, setValidate] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <Drawer
      isOpen={isOpen}
      size={"md"}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton paddingTop={3} />
        <DrawerHeader>Upload your race result</DrawerHeader>

        <DrawerBody>
          <FormControl isInvalid={validate && !selectedRace}>
            <FormLabel>Choose the race</FormLabel>
            <Select
              onChange={(e) => {
                setSelectedRace(e.target.value);
              }}
              placeholder="Select option"
            >
              {races &&
                races.map((race, index) => {
                  return (
                    <option key={index} value={race.id}>
                      {race.name}
                    </option>
                  );
                })}
            </Select>
            {!(validate && !selectedRace) && (
              <FormHelperText>
                Select the race for which you want to upload a result
              </FormHelperText>
            )}
            <FormErrorMessage>You need to select a race</FormErrorMessage>
          </FormControl>
          <FormControl marginTop={5} isInvalid={validate && !position}>
            <FormLabel>Position in age group</FormLabel>
            <Input
              value={position}
              placeholder="12"
              onChange={(e) => {
                const input = Number(e.target.value);
                if (Number.isInteger(input)) {
                  setPosition(e.target.value);
                }
              }}
            />
            {!(validate && !position) && (
              <FormHelperText>
                The position you came within your age group
              </FormHelperText>
            )}
            <FormErrorMessage>
              You need to provide the position you came
            </FormErrorMessage>
          </FormControl>
          <FormControl marginTop={5} isInvalid={validate && !totalCompetitors}>
            <FormLabel>Number of competitors in age group</FormLabel>
            <Input
              value={totalCompetitors}
              placeholder="87"
              onChange={(e) => {
                const input = Number(e.target.value);
                if (Number.isInteger(input)) {
                  setTotalCompetitors(e.target.value);
                }
              }}
            />
            {!(validate && !totalCompetitors) && (
              <FormHelperText>
                The total number of participants in your age group
              </FormHelperText>
            )}
            <FormErrorMessage>
              You need to provide the number of participants that were in your
              age group
            </FormErrorMessage>
          </FormControl>
          <FormControl marginTop={5}>
            <FormLabel>
              Prior to 2025 how many triathlons had you completed?
            </FormLabel>
            <ButtonGroup isAttached variant={"outline"}>
              <Button
                onClick={() => {
                  setIsAspiring(false);
                }}
                colorScheme={isAspiring ? "gray" : "red"}
                variant={isAspiring ? "outline" : "solid"}
              >
                More than three
              </Button>
              <Button
                onClick={() => {
                  setIsAspiring(true);
                }}
                colorScheme={!isAspiring ? "gray" : "red"}
                variant={!isAspiring ? "outline" : "solid"}
              >
                Three or less
              </Button>
            </ButtonGroup>
            <FormHelperText>
              If under 3 you will be competing in the 'Aspiring' category. If
              more than 3 you will be competing in the 'inspiring' category
            </FormHelperText>
          </FormControl>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isUploading}
            colorScheme="red"
            onClick={(e) => {
              setValidate(true);

              if (!position || !totalCompetitors || !selectedRace) {
                return;
              }

              setIsUploading(true);

              const uploadRequestBody = {
                raceId: selectedRace,
                positionInAgeGroup: position,
                totalCompetitorsInAgeGroup: totalCompetitors,
                championshipCategory: isAspiring ? "Aspiring" : "Inspiring",
              };
              const idToken = GetCookie("idToken");
              AddRaceResult(idToken, uploadRequestBody)
                .then(() => {
                  setIsUploading(false);
                  toast({
                    title:
                      "Congrats! Your result has been successfully recorded",
                    status: "success",
                    isClosable: true,
                  });
                  onClose();
                })
                .catch((e) => {
                  e.then((json) => {
                    setIsUploading(false);
                    toast({
                      title: json.error,
                      status: "error",
                      isClosable: true,
                    });
                    onClose();
                  });
                });
            }}
          >
            Upload Result
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
