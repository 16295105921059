import React, { useState } from "react";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../../../components/header/Header.js";
import { ReactComponent as AwardIcon } from "feather-icons/dist/icons/award.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import {
  CloseModalButton,
  Container,
  DecoratorBlob1,
  DecoratorBlob2,
  Heading,
  HighlightedText,
  IllustrationContainer,
  LeftColumn,
  Paragraph,
  RightColumn,
  StyledModal,
  TwoColumn,
} from "./ClubChampionshipsHero.styles.js";
import { Button, useDisclosure } from "@chakra-ui/react";
import { GetCookie, IsExpired } from "api/jwt.js";
import { expiredAndRedirect } from "api/secureApi.ts";
import UploadResultDrawer from "./UploadResultDrawer.js";

export default ({
  heading = "Get ready for the 2025 club championships!",
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Header />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              <>
                The BADTri 2025 <br></br>{" "}
                <HighlightedText>Club Championships</HighlightedText>
              </>
            </Heading>
            <Paragraph>
              The BADTri club championships have always been about encouraging
              participation, friendly competition and providing races where
              you'll know and recognise other club members.
              <br />
              <br />
              We want our club championships to be open and accessible to all,
              and make sure that anyone taking part has a fair and realistic
              chance of being a contender.
              <br />
              <br />
              Check out this years races below, as well as our all new
              leaderboard to see who's winning. Once you've completed a race
              upload your result below.
            </Paragraph>

            <Button
              colorScheme="red"
              size={"lg"}
              rightIcon={<AwardIcon size={14} />}
              onClick={() => {
                const idToken = GetCookie("idToken");
                if (idToken) {
                  if (IsExpired(idToken)) {
                    expiredAndRedirect();
                    return;
                  }
                } else {
                  expiredAndRedirect();
                  return;
                }
                onOpen();
              }}
            >
              Upload your result
            </Button>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal} ref={btnRef}>
            <CloseIcon tw="tw-w-6 tw-h-6" />
          </CloseModalButton>
        </StyledModal>
      </Container>
      <UploadResultDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </>
  );
};
