import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as first } from "images/1st-prize-icon.svg";
import { ReactComponent as second } from "images/2nd-prize-icon.svg";
import { ReactComponent as third } from "images/3rd-prize-icon.svg";
import { Skeleton } from "@chakra-ui/react";
import useRaces from "../hooks/useRaces";

const InformationContainer = tw.div`tw-w-full tw-pr-4`;
const Container = tw(
  ContainerBase,
)`tw-my-8 lg:tw-my-10 tw-bg-primary-900 tw-text-gray-100 -tw-mx-8 lg:tw-px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(
  SectionHeading,
)`sm:tw-text-3xl md:tw-text-4xl lg:tw-text-5xl`;
const Subheading = tw(SubheadingBase)`tw-text-gray-100 tw-text-center`;
const Description = tw(
  SectionDescription,
)`tw-text-gray-400 tw-text-center tw-mx-auto tw-max-w-screen-md`;

const RacesContainer = tw.div`tw-mt-8 tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center tw-flex-wrap tw-justify-between tw-gap-12 lg:tw-gap-0`;
const TrophyFirst = tw(first)`tw-w-10 tw-mx-8`;
const TrophySecond = tw(second)`tw-w-10 tw-mx-8`;
const TrophyThird = tw(third)`tw-w-10 tw-mx-8`;
const LeaderBoardContainer = tw.div`tw-px-5 md:tw-px-10 xl:tw-px-20 tw-w-full lg:tw-w-[49%]`;
const GoldPositionContainer = tw.div`tw-border-[#ffad02] tw-border-solid tw-border-l-8 tw-py-4 tw-bg-[#FFD700] tw-rounded-md tw-my-2 tw-flex`;
const SilverPositionContainer = tw.div`tw-py-4 tw-bg-[#C0C0C0] tw-rounded-md tw-my-2 tw-flex`;
const BronzePositionContainer = tw.div`tw-py-4 tw-bg-[#CD7F32] tw-rounded-md tw-my-2 tw-flex`;
const NameContainer = tw.div`tw-my-auto tw-font-bold tw-text-gray-800 tw-text-2xl`;
const PointsContainer = tw.div`tw-my-auto tw-text-gray-700 tw-text-lg tw-italic`;

export default ({
  subheading = "WHO IS WINNING?",
  heading = "Live Leaderboard",
  description = "The leaderboard is populated through self upload. See the top of the page to upload your own result. Something look wrong? Contact the webmaster.",
}) => {
  const { podiumResults, isLoading } = useRaces();

  console.log(podiumResults);

  return (
    <Container id="#leaderboard">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <RacesContainer>
          <LeaderBoardContainer>
            <Heading tw="tw-mx-auto tw-font-bold tw-text-3xl tw-mb-6">
              Inspiring
            </Heading>
            <GoldPositionContainer>
              <TrophyFirst></TrophyFirst>
              <InformationContainer>
                <Skeleton isLoaded={!isLoading}>
                  <NameContainer>
                    {podiumResults?.inspiring?.[0].name === ""
                      ? "Not enough results"
                      : podiumResults?.inspiring[0].name}
                  </NameContainer>
                  <PointsContainer>
                    {podiumResults?.inspiring?.[0].score === 0
                      ? "0 points"
                      : podiumResults?.inspiring[0].score + " points"}
                  </PointsContainer>
                </Skeleton>
              </InformationContainer>
            </GoldPositionContainer>
            <SilverPositionContainer>
              <TrophySecond></TrophySecond>
              <InformationContainer>
                <Skeleton isLoaded={!isLoading}>
                  <NameContainer>
                    {podiumResults?.inspiring?.[1].name === ""
                      ? "Not enough results"
                      : podiumResults?.inspiring[1].name}
                  </NameContainer>
                  <PointsContainer>
                    {podiumResults?.inspiring?.[1].score === 0
                      ? "0 points"
                      : podiumResults?.inspiring[1].score + " points"}
                  </PointsContainer>
                </Skeleton>
              </InformationContainer>
            </SilverPositionContainer>
            <BronzePositionContainer>
              <TrophyThird></TrophyThird>
              <InformationContainer>
                <Skeleton isLoaded={!isLoading}>
                  <NameContainer>
                    {podiumResults?.inspiring?.[2].name === ""
                      ? "Not enough results"
                      : podiumResults?.inspiring[2].name}
                  </NameContainer>
                  <PointsContainer>
                    {podiumResults?.inspiring?.[2].score === 0
                      ? "0 points"
                      : podiumResults?.inspiring[2].score + " points"}
                  </PointsContainer>
                </Skeleton>
              </InformationContainer>
            </BronzePositionContainer>
          </LeaderBoardContainer>
          <LeaderBoardContainer>
            <Heading tw="tw-mx-auto tw-font-bold tw-text-3xl tw-mb-6">
              Aspiring
            </Heading>
            <GoldPositionContainer>
              <TrophyFirst></TrophyFirst>
              <InformationContainer>
                <Skeleton isLoaded={!isLoading}>
                  <NameContainer>
                    {podiumResults?.aspiring?.[0].name === ""
                      ? "Not enough results"
                      : podiumResults?.aspiring[0].name}
                  </NameContainer>
                  <PointsContainer>
                    {podiumResults?.aspiring?.[0].score === 0
                      ? "0 points"
                      : podiumResults?.aspiring[0].score + " points"}
                  </PointsContainer>
                </Skeleton>
              </InformationContainer>
            </GoldPositionContainer>
            <SilverPositionContainer>
              <TrophySecond></TrophySecond>
              <InformationContainer>
                <Skeleton isLoaded={!isLoading}>
                  <NameContainer>
                    {podiumResults?.aspiring?.[1].name === ""
                      ? "Not enough results"
                      : podiumResults?.aspiring[1].name}
                  </NameContainer>
                  <PointsContainer>
                    {podiumResults?.aspiring?.[1].score === 0
                      ? "0 points"
                      : podiumResults?.aspiring[1].score + " points"}
                  </PointsContainer>
                </Skeleton>
              </InformationContainer>
            </SilverPositionContainer>
            <BronzePositionContainer>
              <TrophyThird></TrophyThird>
              <InformationContainer>
                <Skeleton isLoaded={!isLoading}>
                  <NameContainer>
                    {podiumResults?.aspiring?.[2].name === ""
                      ? "Not enough results"
                      : podiumResults?.aspiring[2].name}
                  </NameContainer>
                  <PointsContainer>
                    {podiumResults?.aspiring?.[2].score === 0
                      ? "0 points"
                      : podiumResults?.aspiring[2].score + " points"}
                  </PointsContainer>
                </Skeleton>
              </InformationContainer>
            </BronzePositionContainer>
          </LeaderBoardContainer>
        </RacesContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
