import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import useRaces from "../hooks/useRaces";

const Container = tw(
  ContainerBase,
)`tw-my-8 lg:tw-my-10 tw-bg-primary-900 tw-text-gray-100 -tw-mx-8 tw-px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(
  SectionHeading,
)`sm:tw-text-3xl md:tw-text-4xl lg:tw-text-5xl`;
const Description = tw(
  SectionDescription,
)`tw-text-gray-400 tw-text-center tw-mx-auto tw-max-w-screen-md`;

const RacesContainer = tw.div`tw-mt-8 tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center tw-flex-wrap tw-max-w-screen-md tw-justify-between tw-mx-auto`;
const Race = tw.div`tw-w-[100%] tw-bg-primary-800 tw-mt-4 tw-px-5 tw-p-4 tw-drop-shadow-lg`;
const RaceDetail = tw.div`tw-text-lg tw-font-medium tw-my-auto -tw-mt-3 tw-inline`;
const RaceName = tw.div`tw-text-xl sm:tw-text-3xl md:tw-text-4xl lg:tw-text-5xl tw-font-black`;

export default () => {
  const initialFocusRef = React.useRef();
  const { races, isLoading } = useRaces();

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>The selected races</Heading>
          <Description>
            Below is a list of all the races which are included in the BADTri
            club championships. Make sure when you enter you put your club as
            'BADTri'
          </Description>
        </HeadingContainer>
        <RacesContainer>
          {!isLoading && races && races.length === 0 && (
            <Race tw="tw-z-0">
              <RaceName tw="tw-text-center tw-text-2xl">
                We haven't yet released our 2025 championship races
              </RaceName>
              <>
                <RaceDetail tw="tw-text-center tw-w-[100%] tw-block tw-mt-3">
                  Our championships organiser, Loz, is working hard to finalise
                  the 2025 race calendar. Check back here soon!
                </RaceDetail>
              </>
            </Race>
          )}
          {!isLoading &&
            races &&
            races.map((race, index) => (
              <Race key={index} tw="tw-z-0">
                <RaceName>{race.name}</RaceName>
                <>
                  <RaceDetail>{race.date}</RaceDetail> &#x2022;
                  <RaceDetail> {race.distance}</RaceDetail> &#x2022;{" "}
                  <Popover initialFocusRef={initialFocusRef} placement="top">
                    <PopoverTrigger>
                      <RaceDetail tw="tw-underline tw-decoration-dotted tw-cursor-pointer tw-italic">
                        {race.discountAvailable} discount available
                      </RaceDetail>
                    </PopoverTrigger>
                    <PopoverContent
                      color="white"
                      bg="blue.800"
                      borderColor="blue.800"
                    >
                      <PopoverArrow bg="blue.800" />
                      <PopoverCloseButton />
                      <PopoverBody>
                        See Spond event for discount code
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </>
                <br />
                {race.entryLink && (
                  <Button
                    marginTop={2}
                    as={"a"}
                    href={race.entryLink}
                    rightIcon={<ExternalLinkIcon />}
                    colorScheme="red"
                    cursor={"pointer"}
                  >
                    Enter Here
                  </Button>
                )}
              </Race>
            ))}
        </RacesContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
