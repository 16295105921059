import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ClubChampionshipsHero from "./components/ClubChampionshipsHero.js";
import ClubChampionshipRaces from "./components/ClubChampionshipRaces";
import Scoring from "components/features/Scoring";
import Leaderboard from "./components/Leaderboard.js";
import Prizes from "components/features/Prizes";

export default () => {
  return (
    <AnimationRevealPage>
      <ClubChampionshipsHero />
      <ClubChampionshipRaces />
      <Scoring />
      <Leaderboard />
      <Prizes />
      <Footer />
    </AnimationRevealPage>
  );
};
