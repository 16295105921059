import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line

import ReactModalAdapter from "../../../helpers/ReactModalAdapter.js";

import { ReactComponent as SvgDecoratorBlob1 } from "./../../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "./../../../images/dot-pattern.svg";

export const Container = tw.div`tw-relative`;
export const TwoColumn = tw.div`tw-flex tw-flex-col lg:tw-flex-row md:tw-items-center tw-max-w-screen-xl tw-mx-auto tw-py-20 md:tw-py-24`;
export const LeftColumn = tw.div`tw-relative lg:tw-w-8/12 tw-flex-shrink-0 tw-text-center lg:tw-text-left`;
export const RightColumn = tw.div`tw-relative tw-mt-12 lg:tw-mt-0 tw-flex tw-flex-col tw-justify-center`;
export const HighlightedText = tw.span`tw-bg-primary-500 tw-text-gray-100 tw-px-4 tw-transform -tw-skew-x-12 tw-inline-block`;

export const Heading = tw.h1`tw-font-black tw-text-3xl md:tw-text-5xl tw-leading-snug tw-max-w-3xl`;
export const Paragraph = tw.p`tw-my-5 lg:tw-my-8 tw-text-sm lg:tw-text-base tw-font-medium tw-text-gray-600 tw-max-w-lg tw-mx-auto lg:tw-mx-0`;

export const Actions = tw.div`tw-flex tw-flex-col tw-items-center sm:tw-flex-row tw-justify-center lg:tw-justify-start tw-mt-8`;
export const WatchVideoButton = styled.button`
  ${tw`tw-mt-4 sm:tw-mt-0 tw-px-4 tw-bg-primary-600 tw-py-2 tw-flex tw-items-center tw-transition tw-duration-300 hover:tw-bg-primary-800 tw-rounded-md`}
  .playIcon {
    ${tw`tw-stroke-1 tw-w-8 tw-h-8 tw-text-white`}
  }
  .playText {
    ${tw`tw-ml-2 tw-font-medium tw-text-white `}
  }
`;

export const IllustrationContainer = tw.div`tw-flex tw-justify-center md:tw-justify-end tw-items-center tw-relative tw-max-w-3xl lg:tw-max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
export const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none tw-opacity-5 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-transform -tw-translate-x-2/3  -tw-z-10`}
`;
export const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none tw-fill-current tw-text-primary-500 tw-opacity-25 tw-absolute tw-w-32 tw-h-32 tw-right-0 tw-bottom-0 tw-transform tw-translate-x-10 tw-translate-y-10 -tw-z-10`}
`;

export const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`tw-fixed tw-inset-0 tw-z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:tw-mx-auto tw-m-4 sm:tw-m-16 tw-max-w-screen-xl tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-bg-gray-200 tw-outline-none`}
  }
  .content {
    ${tw`tw-w-full lg:tw-p-16`}
  }
`;
export const CloseModalButton = tw.button`tw-absolute tw-top-0 tw-right-0 tw-mt-8 tw-mr-8 hocus:tw-text-primary-500`;
